export default {
    data() {
        return {
            inputRules: {
                'None': [],
                'required': [
                v => !!v || this.$t('inputRules.required')
                ],
                'minLength': [
                v => !!v || this.$t('inputRules.required'),
                v => v && v.length >= 6 || this.$t('inputRules.minLength6')
                ],
                'maxLength1000': [
                v => !!v || this.$t('inputRules.required'),
                v => v && v.length <= 1000 || this.$t('inputRules.maxLength1000')
                ],
                'maxLength100': [
                v => !!v || this.$t('inputRules.required'),
                v => v && v.length <= 100 || this.$t('inputRules.maxLength100')
                ],
                'maxLength64': [
                v => !!v || this.$t('inputRules.required'),
                v => v && v.length <= 64 || this.$t('inputRules.maxLength64')
                ],
                'maxLength80': [
                v => !!v || this.$t('inputRules.required'),
                v => v && v.length <= 80 || this.$t('inputRules.maxLength80')
                ],
                'maxLength32': [
                v => !!v || this.$t('inputRules.required'),
                v => v && v.length <= 32 || this.$t('inputRules.maxLength32')
                ],
                'usernameTest': [
                v => !!v || this.$t('inputRules.required'),
                v => v && v.length <= 32 || this.$t('inputRules.maxLength32'),
                v => v && !v.includes('@') || this.$t('inputRules.usernameAt')
                ],
                'emailTest': [
                v => !!v || this.$t('inputRules.required'),
                v => /^[\w.-]+@[\w-]+\.[a-z]{2,8}(\.[a-z]{2,8})?$/.test(v) || this.$t('inputRules.wrongEmail'),
                v => v && v.length <= 80 || this.$t('inputRules.maxLength80')
                ],
                'samePass': [
                v => !!v || this.$t('inputRules.required'),
                v => v==this.inputRulesData || this.$t('inputRules.samePassword')
                ],
                'phoneTest': [
                v => !v || /^(\+\d{1,3}\s?\d{3}\s?\d{3}\s?\d{3}|\d{4}\s?\d{3}\s?\d{3})$/.test(v) || this.$t('inputRules.wrongPhone')
                ],
                'isPositiveNumber': [
                v => !!v || this.$t('inputRules.required'),
                v => /^\d+$/.test(v) || this.$t('inputRules.notNumber'),
                v => parseInt(v) > 0 || this.$t('inputRules.notInteger'),
                v => parseInt(v) < 10000000 || this.$t('inputRules.numberSize')
                ],
                'dateTestFuture': [
                v => !!v || this.$t('inputRules.required'),
                v => /^(0[1-9]|[12]\d|3[01])\.(0[1-9]|1[0-2])\.([12]\d{3})$/.test(v) || this.$t('inputRules.dateFormat'),
                v => this.checkDateFuture(v) || this.$t('inputRules.dateFuture')
                ],
                'dateTestMore': [
                    v => !!v || this.$t('inputRules.required'),
                    v => /^(0[1-9]|[12]\d|3[01])\.(0[1-9]|1[0-2])\.([12]\d{3})$/.test(v) || this.$t('inputRules.dateFormat'),
                    v => this.dateDifference(v, this.inputRulesData) || this.$t('inputRules.dateDifference')
                ],
                'timeTest': [
                v => !!v || this.$t('inputRules.required'),
                v => /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/.test(v) || this.$t('inputRules.timeFormat')
                ],
                'timeTestFuture': [
                    v => !!v || this.$t('inputRules.required'),
                    v => /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/.test(v) || this.$t('inputRules.timeFormat'),
                    v => v && this.timeFuture(this.inputRulesData, v) || this.$t('inputRules.timeFuture')
                ],
                'timeTestMore': [
                    v => !!v || this.$t('inputRules.required'),
                    v => /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/.test(v) || this.$t('inputRules.timeFormat'),
                    v => this.timeDifference(v, this.inputRulesData) || this.$t('inputRules.timeDifference')
                ],
                'profilePhotoTest': [
                    v => v && (!v.name || ['image/png', 'image/jpeg', 'image/bmp', 'image/jpg', 'image/JPG', 'image/JPEG', 'image/tiff', 'image/tif', 'image/gif'].includes(v.type)) || this.$t('inputRules.photoType'),
                    v => v && (!v.name || v.size < 1000000) || this.$t('inputRules.fileSize')
                ]
            }
        }
    },
    methods: {
        timeFuture(date, time) {
            return (!date || !time) || new Date(`${date}T${time}:00`) >= new Date
        },
        formatDateBack(date) {
            if (date != null) {
                const splitDate = date.split(".")
                return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`
            } else {
                return ''
            }
        },
        timeDifference(time1, time2) {
            return (!time1 || !time2) || (new Date(`2020-01-01T${time1}:00`) > new Date(`2020-01-01T${time2}:00`))
        },
        checkDateFuture(dateFull) {
            if (dateFull) {
                dateFull = this.formatDateBack(dateFull)
                const date = dateFull.split('-')
                if (date.length == 3) {
                    if (/^\d+$/.test(date[0]) && /^\d+$/.test(date[1]) && /^\d+$/.test(date[2]) && date[0].length == 4 && date[1].length == 2 && date[2].length == 2) {
                        return(new Date - new Date(dateFull) < 86400000)
                    }
                }
            }
            return false
        },
        dateDifference(date1, date2) {
            if (date1 && date2) {
                if (date1.includes('.')) {
                    date1 = this.formatDateBack(date1)
                }
                if (date2.includes('.')) {
                    date2 = this.formatDateBack(date2)
                }
                return new Date(date1) > new Date(date2)
            }
            else {
                return true
            }
        }
    }
}