<template>
    <div>
        <v-text-field
            v-if="inputType=='text' || inputType=='email'"
            dark
            :background-color="disabled ? 'rgb(235, 181, 157)' : 'rgb(255,157,113)'"
            color="white"
            rounded 
            filled 
            :label="label" 
            @input="(value) => {$emit('input', value)}"
            :rules="inputRules[rules]"
            v-model="value"
            :type="inputType"
            :disabled="disabled">
            <template v-slot:prepend-inner><v-icon class="white--text mr-2">{{icon}}</v-icon></template>
            <template v-if="append" v-slot:append><span class="white--text">{{append}}</span></template>
            <template v-slot:label>
                <span class="white--text">{{label}}</span>
            </template>
        </v-text-field>
        <v-text-field
            v-else-if="inputType=='password'" 
            dark
            :background-color="disabled ? 'rgb(235, 181, 157)' : 'rgb(255,157,113)'"
            color="white"
            rounded 
            filled 
            :label="label"
            @input="(value) => {$emit('input', value)}"
            :rules="inputRules[rules]"
            :type="$store.state.showPassword ? 'text' : 'password'"
            v-model="value"
            :disabled="disabled"
            >
            <template v-slot:append><v-icon @click="$store.commit('switchPassword')" class="white--text mr-2" v-text="$store.state.showPassword ? 'visibility' : 'visibility_off'"></v-icon></template>
            <template v-slot:prepend-inner><v-icon class="white--text mr-2">{{icon}}</v-icon></template>
            <template v-slot:label>
                <span class="white--text">{{label}}</span>
            </template>
        </v-text-field>
        <v-textarea
            auto-grow
            v-else-if="inputType=='textarea'"
            dark
            :background-color="disabled ? 'rgb(235, 181, 157)' : 'rgb(255,157,113)'"
            color="white"
            rounded 
            filled
            :label="label"
            v-model="value"
            @input="(value) => {$emit('input', value)}"
            :rules="inputRules[rules]"
            :disabled="disabled"
            >
            <template v-slot:prepend-inner><v-icon class="white--text mr-2">{{icon}}</v-icon></template>
            <template v-if="append" v-slot:append><span class="white--text">{{append}}</span></template>
            <template v-slot:label>
                <span class="white--text">{{label}}</span>
            </template>
        </v-textarea>
        <v-menu max-width="290px" :close-on-content-click="false" v-model="dateDisplay" v-else-if="inputType=='date'">
            <template v-slot:activator="{on}">
                <v-text-field 
                dark
                :background-color="disabled ? 'rgb(235, 181, 157)' : 'rgb(255,157,113)'"
                color="white"
                rounded 
                filled 
                :label="label"
                v-on="on" 
                :value="formatDate(value)"
                :rules="inputRules[rules]"
                :disabled="disabled"
                @input="(val) => {if(/^(0[1-9]|[12]\d|3[01])\.(0[1-9]|1[0-2])\.([12]\d{3})$/.test(val)) { value=formatDateBack(val); $emit('input', formatDateBack(val)); };}"
                >
                <template v-slot:prepend-inner><v-icon class="white--text mr-2">{{ icon }}</v-icon></template>
                <template v-if="append" v-slot:append><span class="white--text">{{append}}</span></template>
                <template v-slot:label>
                    <span class="white--text">{{ label }}</span>
                </template>
                </v-text-field>
            </template>
            <v-date-picker locale="sk-SK" first-day-of-week=1 v-model="value" @input="(value) => {$emit('input', value); dateDisplay=false}"></v-date-picker>
        </v-menu>
        <v-menu max-width="290px" :close-on-content-click="false" v-model="timeDisplay" v-else-if="inputType=='time'">
            <template v-slot:activator="{on}">
                <v-text-field 
                dark
                :background-color="disabled ? 'rgb(235, 181, 157)' : 'rgb(255,157,113)'"
                color="white"
                rounded 
                filled 
                :label="label"
                v-on="on" 
                :value="value"
                :rules="inputRules[rules]"
                :disabled="disabled"
                @input="(val) => {if(/^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/.test(val)) { value=val }; $emit('input', val)}">
                <template v-slot:prepend-inner><v-icon class="white--text mr-2">{{icon}}</v-icon></template>
                <template v-if="append" v-slot:append><span class="white--text">{{append}}</span></template>
                <template v-slot:label>
                    <span class="white--text">{{label}}</span>
                </template>
                </v-text-field>
            </template>
            <v-time-picker v-model="value" format="24hr" @input="(value) => {$emit('input', value)}"></v-time-picker>
        </v-menu>
        <v-autocomplete v-else-if="inputType=='regionAutocomplete'"
            dark
            :background-color="disabled ? 'rgb(235, 181, 157)' : 'rgb(255,157,113)'"
            color="white"
            rounded 
            filled
            :items="getRegionsItems()"
            item-text="displayValue"
            :label="label"
            :rules="inputRules[rules]"
            @input="(value) => {$emit('input', value)}"
            :disabled="disabled"
            v-model="value"
            :no-data-text="this.$t('data.noData')"
      >
        <template v-slot:prepend-inner><v-icon class="white--text mr-2">{{icon}}</v-icon></template>
        <template v-slot:label>
            <span class="white--text">{{label}}</span>
        </template>
      </v-autocomplete>
        <v-autocomplete v-else-if="inputType=='autocomplete'"
            dark
            :background-color="disabled ? 'rgb(235, 181, 157)' : 'rgb(255,157,113)'"
            color="white"
            rounded 
            filled
            :items="items"
            item-text="name"
            :label="label"
            :rules="inputRules[rules]"
            @input="(value) => {$emit('input', value)}"
            :disabled="disabled"
            v-model="value"
            :no-data-text="this.$t('data.noData')"
      >
        <template v-slot:prepend-inner><v-icon class="white--text mr-2">{{icon}}</v-icon></template>
        <template v-slot:label>
            <span class="white--text">{{label}}</span>
        </template>
      </v-autocomplete>
      <div v-else-if="inputType=='selectTags'">
          <div class="subtitle-1 grey--text text--darken-1">{{label}}</div>
          <div v-if="tagsColumn">
              <v-row wrap>
                  <v-col cols=6 class="pa-0" v-for="(tag, index) in Object.keys($store.state.tags)" :key="index" >
                      <Tag
                        :name="tag" 
                        class="mt-2"
                        :clickable="true"
                        :invertedColors="!selectTags.includes(tag)"
                        @click="tagClick(tag)"/>
                  </v-col>
              </v-row>
          </div>
          <div v-else>
            <Tag v-for="(tag, index) in Object.keys($store.state.tags)" :key="index" 
              :name="tag" 
              class="mt-2" 
              :invertedColors="!selectTags.includes(tag)"
              :clickable="true"
              @click="tagClick(tag)"
            />
          </div>
      </div>
      <div v-else-if="inputType=='selectUserTags'">
          <div class="subtitle-1 grey--text text--darken-1">{{label}}</div>
          <div v-if="tagsColumn">
              <v-row wrap>
                  <v-col 
                    cols=6 
                    class="pa-0"
                    v-for="(tag, index) in Object.keys($store.state.tags).filter(t => $store.state.tags[t].isUser)" 
                    :key="index" 
                   >
                      <Tag
                        :name="tag" 
                        class="mt-2" 
                        :invertedColors="!selectUserTags.includes(tag)"
                        @click="tagClick(tag)"
                      />
                  </v-col>
              </v-row>
          </div>
          <div v-else>
            <Tag v-for="(tag, index) in Object.keys($store.state.tags).filter(t => $store.state.tags[t].isUser)" :key="index" 
              :name="tag" 
              class="mt-2" 
              :invertedColors="!selectUserTags.includes(tag)"
              @click="tagClick(tag)"
            />
          </div>
      </div>
    <v-select v-else-if="inputType=='select'"
        dark
        :background-color="disabled ? 'rgb(235, 181, 157)' : 'rgb(255,157,113)'"
        color="white"
        rounded 
        filled
        :items="items"
        :label="label"
        :rules="inputRules[rules]"
        @input="(value) => {$emit('input', value)}"
        :disabled="disabled"
        loader-height=100
    >
        <template v-slot:prepend-inner><v-icon class="white--text mr-2">{{icon}}</v-icon></template>
        <template v-slot:label>
            <span class="white--text">{{label}}</span>
        </template>
    </v-select>
    <v-menu max-width="290px" :close-on-content-click="false" v-model="dateRangeDisplay" v-else-if="inputType=='dateRange'">
        <template v-slot:activator="{on}">
            <v-text-field 
            dark
            :background-color="disabled ? 'rgb(235, 181, 157)' : 'rgb(255,157,113)'"
            color="white"
            rounded 
            filled 
            :label="label"
            v-on="on" 
            :value="formatDateRange(value)"
            :rules="inputRules[rules]"
            :disabled="disabled"
            >
            <template v-slot:prepend-inner><v-icon class="white--text mr-2">{{ icon }}</v-icon></template>
            <template v-if="append" v-slot:append><span class="white--text">{{ append }}</span></template>
            <template v-slot:label>
                <span class="white--text">{{ label }}</span>
            </template>
            </v-text-field>
        </template>
        <v-date-picker locale="sk-SK" first-day-of-week=1 range v-model="value" @input="(value) => {$emit('input', value)}"></v-date-picker>
    </v-menu>
    </div>
</template>

<script>
import inputRulesMixin from '@/mixins/inputRulesMixin.js'
import utilitiesMixin from '@/mixins/utilitiesMixin.js'
import Tag from '@/components/partials/Tag'
    export default {
        name: "InputField",
        mixins: [inputRulesMixin, utilitiesMixin],
        components: {Tag},
        props: {
            rules: {
                type: String,
                default: 'None'
            },
            label: {
                type: String,
                default: 'InputField'
            },
            icon: {
                type: String,
                default: ''
            },
            inputType: {
                type: String,
                default: 'text'
            },
            append: {
                type: String,
                default: ''
            },
            items: {
                type: Array
            },
            inputValue: {
                type: [Array, String, Number]
            },
            disabled: {
                type: Boolean,
                default: false
            },
            tagsColumn: {
                type: Boolean,
                default: false
            },
            inputRulesData: {
                type: [String, Boolean, Array, Object]
            },
            regionSlovakia: {
                type: Boolean,
                default: true
            },
            regionAll: {
                type: Boolean,
                default: false
            },
            regionMy: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                showPassword: false,
                value: this.inputValue || null,
                dateDisplay: false,
                dateRangeDisplay: false,
                timeDisplay: false,
                selectTags: [],
                selectUserTags: []
            }
        },
        watch: {
            inputValue: function() {
                if (this.inputType == 'selectTags') {
                    this.selectTags = this.inputValue
                }
                else if (this.inputType == 'selectUserTags') {
                    this.selectUserTags = this.inputValue
                }
                else {
                    this.value = this.inputValue
                }
            },
            inputRulesData: function() {
                if (this.rules == 'timeTestFuture') {
                    if (this.value) {
                        this.value += ' '
                        let scope = this;
                        setTimeout(() => {
                            scope.value = scope.value.substring(0, scope.value.length - 1)
                        }, 100)                        
                    }
                }
            }
        },
        mounted() {
            if (this.inputValue) {
                if (this.inputType == 'selectTags') {
                    this.selectTags = this.inputValue
                }
                else if (this.inputType == 'selectUserTags') {
                    this.selectUserTags = this.inputValue
                }
                else {
                    this.value = this.inputValue
                }
            }
        },
        methods: {
            tagClick: function(tag) {
                var index = this.selectTags.indexOf(tag);
                if (index !== -1) {
                    this.selectTags.splice(index, 1);
                }
                else {
                    this.selectTags.push(tag)
                }
                this.$emit('input', this.selectTags)
            },
            getRegionsItems() {
                let items = Object.keys(this.$store.state.regions).map(key => this.$store.state.regions[key]);
                if (!this.regionSlovakia) {
                    items = items.slice(1)
                }
                if (this.regionMy) {
                    items.unshift({value: 'data.myRegion', displayValue: this.$t("data.myRegion")})
                }
                if (this.regionAll) {
                    items.unshift({value: 'data.allRegions', displayValue: this.$t("data.allRegions")})
                }
                return items
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>