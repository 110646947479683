<template>
    <v-dialog
        v-model="dialog"
        width="500"
        persistent
    >
    <v-card>
        <div v-if="dialogType=='error'">
            <v-card-title primary-title>
                <v-icon large class="mr-3 red--text">error</v-icon><span class="display-1 red--text">{{ $t("errors.errorTitle") }}</span>
            </v-card-title>

            <v-card-text class="subtitle-1">
              {{ dialogText || $t("errors.errorText") }}
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="dialog = false; $router.push({name: 'landing'})"
            >
                {{ $t("errors.back") }}
            </v-btn>
            </v-card-actions>
        </div>
        <div v-else-if="dialogType=='info'">
            <v-card-title primary-title>
                <span class="display-1 primary--text">{{dialogTitle}}</span>
            </v-card-title>

            <v-card-text class="subtitle-1">
            {{ dialogText || $t("errors.errorText") }}
            <InputField
              v-if="askForPassword"
              :label="$t('popup.password')"
              icon="lock"
              v-model="password"
              rules="minLength"
              inputType="password"
              :class="[$vuetify.breakpoint.mdAndDown ? 'mx-1' : 'mx-10', 'mt-3']"
            />
            </v-card-text>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="dialog = false; $emit('dialogCancel')"
            >
                {{ $t("popup.cancel") }}
            </v-btn>
            <v-btn
                color="primary"
                text
                @click="dialog = false; !askForPassword ? $emit('dialogOk') : $emit('dialogOk', password)"
            >
                {{ $t("popup.confirm") }}
            </v-btn>
            </v-card-actions>
        </div>
    </v-card>
    </v-dialog>
</template>

<script>
import InputField from '@/components/partials/InputField'

    export default {
        name: "ErrorDialog",
        components: {InputField},
        props: {
            dialogOpen: {
                type: Boolean,
                default: false
            },
            dialogText: {
                type: String,
                default: null
            },
            dialogTitle: {
                type: String,
                default: ''
            },
            dialogType: {
                type: String,
                default: 'error'
            },
            askForPassword: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                dialog: this.dialogOpen,
                password: ''
            }
        },
        watch: {
            dialogOpen: function() {
                this.dialog = this.dialogOpen
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
